import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO } from "gatsby-theme-core";
import { Layout, HeaderOne, FooterOne } from "gatsby-theme-layout";
import {
    VideoStreamingContainerTwo,
    HeroSection03,
    BannerSection3,
} from "gatsby-theme-container";
import { normalizedData } from "@streamo/helper/methods";

const StreamingIndex = ({ data }) => {
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO pageName="Movies" />
            <HeaderOne data={{ ...data?.header, ...data.navigation }} />

            <HeroSection03
                data={{
                    items: data?.featuredMoviesPost?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["latest-movies-section"],
                    items: data?.allLatestMoviesPost?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["horror-movies-section"],
                    items: data?.videoStreamingHorrorMovie?.nodes,
                }}
            />
            <BannerSection3
                data={{
                    items: data?.romanticMovieBanner?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["romantic-movies-section"],
                    items: data?.videoStreamingRomanticMovie?.nodes,
                }}
            />
            <VideoStreamingContainerTwo
                data={{
                    ...content?.["comedy-movies-section"],
                    items: data?.videoStreamingComedyMovie?.nodes,
                }}
            />
            <FooterOne data={{ ...data?.footer, ...data?.contact }} />
        </Layout>
    );
};

export const query = graphql`
    query StreamingMovieTowQuery {
        header: general(section: { eq: "header-01" }) {
            ...Header01
        }
        navigation: general(section: { eq: "menu-streaming" }) {
            ...Menu01
        }
        footer: general(section: { eq: "footer-01" }) {
            ...Footer01
        }
        contact: general(section: { eq: "contact" }) {
            ...Contact01
        }
        page(title: { eq: "streaming-movie" }) {
            content {
                ...PageContent
            }
        }
        featuredMoviesPost: allVideoStreamingMovie(
            filter: { is_featured: { eq: true } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        allLatestMoviesPost: allVideoStreamingMovie(
            sort: { fields: date, order: DESC }
            filter: { comingSoon: { eq: false } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        videoStreamingRomanticMovie: allVideoStreamingMovie(
            filter: { category: { elemMatch: { slug: { eq: "romantic" } } } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        romanticMovieBanner: allVideoStreamingMovie(
            filter: {
                category: { elemMatch: { slug: { eq: "romantic" } } }
                is_featured: { eq: true }
            }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        videoStreamingHorrorMovie: allVideoStreamingMovie(
            filter: { category: { elemMatch: { slug: { eq: "horror" } } } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
        videoStreamingComedyMovie: allVideoStreamingMovie(
            filter: { category: { elemMatch: { slug: { eq: "comedy" } } } }
        ) {
            nodes {
                ...VideoStreamingMovie
            }
        }
    }
`;

StreamingIndex.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.shape({}),
        navigation: PropTypes.shape({}),
        footer: PropTypes.shape({}),
        contact: PropTypes.shape({}),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        featuredMoviesPost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allLatestMoviesPost: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        videoStreamingRomanticMovie: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        romanticMovieBanner: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        videoStreamingHorrorMovie: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        videoStreamingComedyMovie: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export default StreamingIndex;
